import React, { useEffect } from 'react';
import download from 'downloadjs';
import Prism from 'prismjs';
import { initializeLineNumbersPlugin } from '../utils/lineNumbers';

import "../styles/prism.css";

const downloadFile = (contents: string) => {
  download(contents, 'Dockerfile');
};

export const Dockerfile = ({ content }: { content: string }) => {
  useEffect(() => {
    setTimeout(() => Prism.highlightAll());
  }, [ content ]);

  useEffect(() => initializeLineNumbersPlugin(), []);

  return (
    <section className="section" style={{ height: '100%' }}>
      <article className="message" style={{ height: '100%' }}>
        <div className="message-header">
          <p>Dockerfile</p>
          <button className="button" aria-label="download" onClick={() => downloadFile(content)}>
            <span className="icon is-small">
              <i className="fas fa-download"></i>
            </span>
          </button>
        </div>
        <div className="message-body line-numbers" style={{ whiteSpace: 'pre-line', padding: 0 }}>
          <pre className="line-numbers" style={{ background: 'whitesmoke', marginTop: 0, marginBottom: 0 }}>
            <code className="language-docker">
              {content}
            </code>
          </pre>
        </div>
      </article>
    </section>
  );
};
