/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

const addCmd = (cmd: string, cmds: string[], setCmds: React.Dispatch<React.SetStateAction<string[]>>, setCmdValue: React.Dispatch<React.SetStateAction<string>>) => {
  if (!cmd) {
    return alert('Must provide valid cmd');
  }

  const newCmds = [...cmds, cmd];

  setCmds(newCmds);

  setCmdValue('');
};

export const CommandList = ({ cmds, setCmds }: { cmds: string[], setCmds: React.Dispatch<React.SetStateAction<string[]>> }) => {
  const [ cmdValue, setCmdValue ] = useState<string>('');

  return (
    <section className="section" style={{ paddingTop: 0 }}>
      <nav className="panel">
        <p className="panel-heading">Commands</p>
        <div className="panel-block">
          <p className="control has-icons-left">
            <input className="input" type="text" placeholder="Command" value={cmdValue} onChange={ev => setCmdValue(ev.target.value)} />
            <span className="icon is-left">
              <i className="fas fa-terminal" aria-hidden="true"></i>
            </span>
          </p>
          <span className="icon is-right" style={{ paddingLeft: '0.5rem' }} onClick={() => addCmd(cmdValue, cmds, setCmds, setCmdValue)}>
            <i className="fas fa-check" aria-hidden="true"></i>
          </span>
        </div>
        {
          cmds.map((cmd, idx) => 
            <a className="panel-block is-active" key={idx}>
              <span className="panel-icon" onClick={() => setCmds(cmds.filter((p, i) => i !== idx))}>
                <i className="fas fa-times" aria-hidden="true"></i>
              </span>
              {cmd}
            </a>
          )
        }
      </nav>
    </section>
  );
};
