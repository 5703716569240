import React from 'react';

export const StartupCmd = ({ startupCmd, setStartupCmd }: { startupCmd: string, setStartupCmd: React.Dispatch<React.SetStateAction<string>> }) => {
  return (
    <section className="section" style={{ paddingTop: 0 }}>
      <nav className="panel">
        <p className="panel-heading">Startup Command</p>
        <div className="panel-block">
          <input className="input" type="text" placeholder="Command" value={startupCmd} onChange={ev => setStartupCmd(ev.target.value)} />
        </div>
      </nav>
    </section>
  );
};
