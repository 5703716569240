import React from 'react';

export const Version = ({ baseImageVersion, setBaseImageVersion, versions }: { baseImageVersion: string, setBaseImageVersion: React.Dispatch<React.SetStateAction<string>>, versions: Array<{ value: string, name: string }> }) => {
  return (
    <section className="section" style={{ paddingTop: 0 }}>
      <nav className="panel">
        <p className="panel-heading">Version</p>
        <div className="panel-block">
          <div className="select" style={{ width: '100%' }}>
            <select id="platformVersion" style={{ width: '100%' }} onChange={ev => setBaseImageVersion(ev.target.value)} value={baseImageVersion}>
              {
                versions.map(({ name, value }, idx) => <option key={idx} value={value}>{name}</option>)
              }
            </select>
          </div>
        </div>
      </nav>
    </section>
  );
};
