import React from 'react';

export const Platform = ({ baseImage, setBaseImage }: { baseImage: string, setBaseImage: React.Dispatch<React.SetStateAction<string>> }) => {
  return (
    <section className="section">
      <nav className="panel">
        <p className="panel-heading">Language/Platfom</p>
        <div className="panel-block">
          <div className="select" style={{ width: '100%' }}>
            <select id="platformSelect" style={{ width: '100%' }} onChange={ev => setBaseImage(ev.target.value)} value={baseImage}>
              <option value="node">Node.js</option>
              <option value="go">Go</option>
              <option value="rustlang/rust">Rust</option>
              <option value="openjdk">Java</option>
              <option value="ubuntu">Ubuntu</option>
            </select>
          </div>
        </div>
      </nav>
    </section>
  );
};
