/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

const addPort = (port: string, ports: number[], setPorts: React.Dispatch<React.SetStateAction<number[]>>, setPortValue: React.Dispatch<React.SetStateAction<string>>) => {
  if (!port) {
    return alert('Must provide valid port');
  }

  if (ports.includes(parseInt(port, 10))) { return setPortValue(''); }

  const newPorts = [ ...ports, parseInt(port, 10) ];

  setPorts(newPorts);

  setPortValue('');
};

export const PortList = ({ ports, setPorts }: { ports: number[], setPorts: React.Dispatch<React.SetStateAction<number[]>> }) => {
  const [ portValue, setPortValue ] = useState<string>('');

  return (
    <section className="section" style={{ paddingTop: 0 }}>
      <nav className="panel">
        <p className="panel-heading">Exposed Ports</p>
        <div className="panel-block">
          <p className="control has-icons-left">
            <input className="input" type="number" placeholder="Port" value={portValue} onChange={ev => setPortValue(ev.target.value)} />
            <span className="icon is-left">
              <i className="fas fa-terminal" aria-hidden="true"></i>
            </span>
          </p>
          <span className="icon is-right" style={{ paddingLeft: '0.5rem' }} onClick={ev => addPort(portValue, ports, setPorts, setPortValue)}>
            <i className="fas fa-check" aria-hidden="true"></i>
          </span>
        </div>
        {
          ports.map((port, idx) => 
            <a className="panel-block is-active" key="idx">
              <span className="panel-icon" onClick={() => setPorts(ports.filter((p, i) => i !== idx))}>
                <i className="fas fa-times" aria-hidden="true"></i>
              </span>
              {port}
            </a>
          )
        }
      </nav>
    </section>
  );
};
