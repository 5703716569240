/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Resource } from '../utils/buildDockerfile';

const LOCATIONS = {
  LOCAL: 'local',
  REMOTE: 'remote'
};

const addResource = (resource: Resource, resources: Resource[], setResources: React.Dispatch<React.SetStateAction<Resource[]>>, setResourceValue: React.Dispatch<React.SetStateAction<string>>) => {
  if (!resource?.location) {
    return alert('Must provide valid cmd');
  }

  const newResources = [...resources, resource];

  setResources(newResources);

  setResourceValue('');
};

export const ResourceList = ({ resources, setResources }: { resources: Resource[], setResources: React.Dispatch<React.SetStateAction<Resource[]>> }) => {
  const [ location, setLocation ] = useState<string>(LOCATIONS.LOCAL);
  const [ resourceValue, setResourceValue ] = useState<string>('');

  return (
    <section className="section" style={{ paddingTop: 0 }}>
      <nav className="panel">
        <p className="panel-heading">Resources</p>
        <div className="panel-block">
          <p className="control has-icons-left">
            <input className="input" type="text" placeholder="Resource" value={resourceValue} onChange={ev => setResourceValue(ev.target.value)} />
            <span className="icon is-left">
              <i className="fas fa-terminal" aria-hidden="true"></i>
            </span>
          </p>
          <span className="icon is-right" style={{ paddingLeft: '0.5rem' }} onClick={() => addResource({ location: resourceValue, isLocal: location === LOCATIONS.LOCAL }, resources, setResources, setResourceValue)}>
            <i className="fas fa-check" aria-hidden="true"></i>
          </span>
        </div>
        <p className="panel-tabs">
          <a className={ location === LOCATIONS.LOCAL ? "is-active" : '' } onClick={() => setLocation(LOCATIONS.LOCAL)}>Local</a>
          <a className={ location === LOCATIONS.REMOTE ? "is-active" : '' } onClick={() => setLocation(LOCATIONS.REMOTE)}>Remote</a>
        </p>
        {
          resources.map((resource, idx) => {
            if (resource.isLocal && location === LOCATIONS.REMOTE) { return null; }

            return (
              <a className="panel-block is-active" key={idx}>
                <span className="panel-icon" onClick={() => setResources(resources.filter((p, i) => i !== idx))}>
                  <i className="fas fa-times" aria-hidden="true"></i>
                </span>
                {resource.location}
              </a>
            );
          })
        }
      </nav>
    </section>
  );
};
