import React, { useState, useEffect, useRef } from 'react';
import 'bulma/css/bulma.css';
import './App.css';
import { buildDockerfile, Resource } from './utils/buildDockerfile';
import { Platform } from './components/platform';
import { Version } from './components/version';
import { ResourceList } from './components/resourceList';
import { CommandList } from './components/commandList';
import { PortList } from './components/portList';
import { StartupCmd } from './components/startupCmd';
import { Dockerfile } from './components/dockerfile';
import { getVersions } from './utils/getVersions';

function usePrevious(value: any) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

const App: React.FC = () => {
  const [ baseImage, setBaseImage ] = useState<string>('node');
  const [ baseImageVersion, setBaseImageVersion ] = useState<string>('');
  const [ resources, setResources ] = useState<Resource[]>([]);
  const [ cmds, setCmds ] = useState<string[]>([]);
  const [ ports, setPorts ] = useState<number[]>([]);
  const [ startupCmd, setStartupCmd ] = useState('');

  const dockerfile = buildDockerfile({
    base: `${baseImage}:${baseImageVersion}`,
    resources,
    cmds,
    ports,
    startupCmd
  });

  const prevImage = usePrevious(baseImage);

  useEffect(() => {
    if (prevImage !== baseImage) {
      setBaseImageVersion('');
    }
  }, [ baseImage, prevImage ]);

  const versions = getVersions(baseImage);

  useEffect(() => setBaseImageVersion(versions[0].value), [ versions ]);

  return (
    <div className="app">
      <section className="hero is-primary">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">
              ContainMyApp
            </h1>
            <h2 className="subtitle">
              A visual tool for containerizing your app
            </h2>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="columns is-desktop">
          <div className="column">
            <Platform {...{ baseImage, setBaseImage }} />

            <Version {...{ baseImageVersion, setBaseImageVersion, versions }} />

            <ResourceList {...{ resources, setResources }} />

            <CommandList {...{ cmds, setCmds }} />

            <PortList {...{ ports, setPorts }} />

            <StartupCmd {...{ startupCmd, setStartupCmd }} />
          </div>
          <div className="column">
            <Dockerfile content={dockerfile} />
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="content has-text-centered">
          <p>
            <strong>ContainMyApp</strong> by <a href="https://lively.software">Lively Software</a><br/>
            &copy;{new Date().getFullYear()}
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
