const NODE = [
  { value: '13', name: 'Current (v13.X.X)' },
  { value: 'erbium', name: 'Erbium(LTS v12)' },
  { value: 'dubnium', name: 'Dubnium (LTS v10)' },
];

const GO = [
  { value: '1.13-alpine3.10', name:  '1.13.X (Alpine)' },
  { value: '1.12-alpine3.10', name: '1.12.X (Alpine)' },
];

const RUST = [
  { value: 'nightly', name: 'Nightly' },
  { value: 'nightly-alpine', name: 'Nightly (Alpine)' },
];

const JAVA = [
  { value: '14-jdk-alpine', name: '14 JDK (Alpine)' },
  { value: '14-alpine', name: '14 (Alpine)' },
];

const UBUNTU = [
  { value: 'latest', name: '19.10 LTS (Latest)' },
  { value: 'bionic', name: '18.04 LTS (Bionic)' },
  { value: 'xenial', name: '16.04 LTS (Xenial)' },
];

export const getVersions = (platform: string): Array<{ value: string, name: string }> => {
  if (platform === 'node') { return NODE; }

  if (platform === 'go') { return GO; }

  if (platform === 'rustlang/rust') { return RUST; }

  if (platform === 'openjdk') { return JAVA; }

  if (platform === 'ubuntu') { return UBUNTU; }

  return [];
};