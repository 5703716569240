export interface Resource {
  location: string;
  isLocal: boolean;
}

interface BuildParams {
  base: string;
  resources: Array<Resource>;
  cmds: string[];
  ports: number[];
  startupCmd: string;
}

export const buildDockerfile = ({ base, resources, cmds, ports, startupCmd }: BuildParams): string => {
  const appBase = '/usr/src/app/';

  const copyCmds = resources.map((resource: Resource) => `${ resource.isLocal ? 'COPY' : 'ADD' } ./${ resource.location } ${ appBase }${ resource.location }`).join('\n');
  const runCmds = cmds.map((cmd: string) => `RUN ${ cmd }`).join('\n');
  const exposedPorts = ports.map((port: number) => `EXPOSE ${ port }`).join('\n');

  let dockerfile = `# Generated using containmy.app \n\n`;

  dockerfile += `FROM ${ base }\n\n`;

  if (copyCmds.length > 0) {
    dockerfile += `${ copyCmds }\n\n`;
  }

  if (runCmds.length > 0) {
    dockerfile += `${ runCmds }\n\n`;
  }

  if (exposedPorts.length > 0) {
    dockerfile += `${ exposedPorts }\n\n`;
  }

  if (startupCmd) {
    dockerfile += `CMD [${ startupCmd.split(' ').map(cmd => `"${ cmd }"`).join(', ') }]\n`;
  }

  return `${ dockerfile }\n`;
};
